import { globalStore } from "@/lib/store";

export interface Theme {
  name: string;
  type: "light" | "dark";
  editor: {
    background: string;
    foreground: string;
    lineHighlight: string;
    selection: string;
    cursor: string;
    gutterBackground: string;
    gutterForeground: string;
  };
  syntax: {
    keyword: string;
    name: string;
    function: string;
    constant: string;
    string: string;
    number: string;
    comment: string;
    operator: string;
    class: string;
    type: string;
    variable: string;
  };
  ui: {
    sideBar: string;
    activityBar: string;
    titleBar: string;
    tabs: string;
    tabsActive: string;
    tabsActiveBorder: string;
    borders: string;
    buttons: string;
    buttonsHover: string;
    text: string;
    titleBarText: string;
    sideBarText: string;
    activityBarText: string;
    panels: string;
  };
}

export const defaultThemes: Theme[] = [
  {
    name: "Enhanced Material",
    type: "dark",
    editor: {
      background: "#292c3e",
      foreground: "#b4b4b4",
      lineHighlight: "#1110173c",
      selection: "#666666",
      cursor: "#b3b4b2",
      gutterBackground: "#292c3e",
      gutterForeground: "#858585",
    },
    syntax: {
      keyword: "#c573de",
      name: "#ff8080",
      function: "#59b4ff",
      constant: "#00fff3",
      string: "#6eff59",
      number: "#00fff3",
      comment: "#008000",
      operator: "#b4b4b4",
      class: "#267f99",
      type: "#ff8f00",
      variable: "#ff8080",
    },
    ui: {
      sideBar: "#1b1e2b",
      activityBar: "#1b1e2b",
      titleBar: "#1b1e2b",
      tabs: "#292c3e",
      tabsActive: "#232636",
      tabsActiveBorder: "#5affb2",
      borders: "#00000059",
      buttons: "#2a4c82",
      buttonsHover: "#1d9bee",
      text: "#ffffff",
      sideBarText: "#b1e2ff",
      activityBarText: "#fff66d",
      titleBarText: "#ffffff",
      panels: "#1b1e2b",
    },
  },
];

const mergeDefaultThemes = (themes: Theme[]): Theme[] => {
  const mergedThemes = [...defaultThemes];

  themes.forEach((theme: Theme) => {
    const idx = defaultThemes.findIndex((dTheme) => theme.name == dTheme.name);

    if (idx == -1) {
      mergedThemes.push(theme);
    }
  });

  return mergedThemes;
};

export interface ThemeState {
  currentTheme: number;
  themes: Theme[];
}

export const createThemeStore = () => {
  if (typeof window === "undefined") {
    globalStore.createStore(
      "themesStore",
      {
        themes: mergeDefaultThemes([]),
        currentTheme: 0,
      },
      []
    );
    return;
  }

  const initialThemeState: ThemeState = {
    themes: mergeDefaultThemes([]),
    currentTheme: 0,
  };

  globalStore.createStore("themesStore", initialThemeState, []);
};
