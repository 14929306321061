import { useState, useEffect } from "react";
import { State, StoreFactory, Subscriber, ComputedField } from "./types";

declare global {
  interface Window {
    globalStore: RootStore;
  }
}

class Store {
  state: State;
  subscribers: Subscriber[];
  computedFields: Record<string, ComputedField[]>;

  constructor(initialState: State, computedFields: ComputedField[]) {
    this.state = structuredClone(initialState);
    this.subscribers = [];
    this.computedFields = {};

    computedFields.forEach((cf) => {
      if (!this.computedFields[cf.source]) {
        this.computedFields[cf.source] = [];
      }
      this.computedFields[cf.source].push(cf);

      const val = this.state[cf.source];
      const computedVal = cf.operation(val);
      this.state[cf.name] = computedVal;
    });
  }

  subscribe(subscriber: Subscriber) {
    this.subscribers.push(subscriber);
    return () => {
      this.subscribers.splice(this.subscribers.indexOf(subscriber), 1);
    };
  }

  isComputedField(selector: (state: State) => any): boolean {
    const computedFieldNames: Record<string, boolean> = {};
    Object.values(this.computedFields)
      .flat()
      .map((cf) => (computedFieldNames[cf.name] = true));
    const selected = selector(computedFieldNames);

    // Single field (selector = ({foo}) => foo)
    if (selected === undefined) {
      return false;
    } else if (selected === true) {
      return true;
    }

    return Object.values(selected).some((val) => val === true);
  }

  notify(
    newState: State | ((state: State) => State),
    callback: Function | null = null
  ) {
    const stateUpdate =
      typeof newState === "function" ? newState(this.state) : newState;
    const rectifiedState = { ...this.state, ...stateUpdate };

    Object.entries(this.computedFields).forEach(([source, fields]) => {
      if (this.state[source] !== rectifiedState[source]) {
        fields.forEach((cf) => {
          const val = rectifiedState[source];
          const computedVal = cf.operation(val);
          rectifiedState[cf.name] = computedVal;
        });
      }
    });

    this.state = rectifiedState;
    this.subscribers.forEach((subscriber) => subscriber({ ...this.state }));

    if (callback) {
      callback(rectifiedState);
    }
  }
}

export class RootStore implements StoreFactory {
  stores: Record<string, Store>;

  constructor() {
    this.stores = {};
  }

  readStore<T>(name: string): T {
    if (!this.stores[name]) {
      throw new Error(`Store ${name} does not exist!`);
    }
    return { ...this.stores[name].state } as T;
  }

  updateStore(name: string, newState: State, callback: Function | null = null) {
    if (!this.stores[name]) {
      throw new Error(`Store ${name} does not exist!`);
    }
    this.stores[name].notify(newState, callback);
  }

  createStore(
    name: string,
    initialState: State,
    computedFields: ComputedField[]
  ) {
    if (this.stores[name]) {
      return this.stores[name];
    }
    const newStore = new Store(initialState, computedFields);
    this.stores[name] = newStore;
    return newStore;
  }

  hook() {
    return <T>(
      storeName: string,
      selector: (state: State) => T
    ): [T, (newState: State, callback?: Function) => void] => {
      const store = this.stores[storeName];
      if (!store) {
        throw new Error(
          `Store ${storeName} does not exist! Check your spelling.`
        );
      }

      const [selectedValue, setSelectedValue] = useState(selector(store.state));

      useEffect(() => {
        const unsubscribe = store.subscribe((newState) => {
          setSelectedValue(selector(newState));
        });
        return unsubscribe;
      }, [store]);

      const update = (newState: State, callback: Function | null = null) => {
        //console.log(`updating store ${storeName} with`, newState);
        //console.trace();
        return store.notify(newState, callback);
      };

      if (store.isComputedField(selector)) {
        return [selectedValue, () => null];
      }

      return [selectedValue, update];
    };
  }
}

export const globalStore = new RootStore();
if (typeof window !== "undefined") {
  window.globalStore = globalStore;
}

export const useStore = globalStore.hook();
