import "@/styles/globals.css";
import type { AppProps } from "next/app";
import type { NextPage } from "next";
import { ReactElement, ReactNode, useEffect } from "react";
import { useStore } from "@/lib/store";
import dynamic from "next/dynamic";
import Analytics from "../components/Analytics";
import Router from "next/router";
import Script from "next/script";
import * as Sentry from "@sentry/nextjs";

import { createUserStore } from "@/stores/user";
import { createThemeStore } from "@/stores/themes";

createUserStore();
createThemeStore();

const ProductFruits = dynamic(
  () => import("react-product-fruits").then((mod) => mod.ProductFruits),
  { ssr: false }
);

Router.events.on("routeChangeComplete", () => {
  // Slight delay to ensure the API fully loads before this triggers.
  setTimeout(() => {
    window &&
      window.productFruits?.pageChanged &&
      window.productFruits.pageChanged();
  }, 1000);
});

import {
  transitions,
  positions,
  Provider as AlertProvider,
} from "@blaumaus/react-alert";
import AlertTemplate from "@/components/AlertTemplate";
import request from "@/lib/requestClient";
import { Organization } from "@/lib/types";
import { signOut } from "@/lib/authentication";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 2000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };

  const [user, updateUser] = useStore("userStore", ({ user }) => user);
  const userInfo = {
    username: user?.id, // REQUIRED - any unique user identifier
    email: user?.email,
  };

  useEffect(() => {
    Sentry.setUser({
      id: user?.id,
      username: user?.email,
      email: user?.email,
    });
  }, [user]);

  const [currentOrganization, updateCurrentOrganization] = useStore(
    "userStore",
    ({ organization }) => organization
  );

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const endpoint = request.endpoint(`auth/me`);
      request
        .call(endpoint, request.createDefaultParams())
        .then((response) => response.json())
        .then((data) => {
          updateUser({ user: data });
          if (window.heap) {
            window.heap.identify(data.email);
          }
          if (localStorage.getItem("organizationId")) {
            const orgId = parseInt(
              localStorage.getItem("organizationId") as string
            );
            const org = data.organizations.find(
              (org: Organization) => org.id === orgId
            );
            updateCurrentOrganization({ organization: org });
          } else {
            localStorage.setItem(
              "organizationId",
              data.organizations[0].id.toString()
            );
            updateCurrentOrganization({ organization: data.organizations[0] });
          }
        });
    } else {
      signOut();
    }
  }, []);

  return getLayout(
    <>
      {global.window && !global.window.productFruits && user && (
        <ProductFruits
          workspaceCode={process.env.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE || ""}
          language="en"
          user={userInfo}
        />
      )}
      <Analytics />
      <Script
        type="text/javascript"
        src="//script.crazyegg.com/pages/scripts/0124/3700.js"
        async={true}
      />
      <AlertProvider template={AlertTemplate} {...options}>
        <Component {...pageProps} />
      </AlertProvider>
    </>
  );
}
